import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type { StoragePool } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { type ApiClient, getResponseData } from "../../modules/api/apiClient.ts";
import { atomAllLocationsQuery } from "../../modules/location/atomAllLocationsQuery.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export interface Params {
    location: string;
}

export interface StoragePoolWithType extends StoragePool, Params {
    $type: "storage_pool";
}

export type Response = Map<StoragePool["uuid"], StoragePoolWithType>;

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["storage/admin/pools"] : ["storage/admin/pools", params.location];
}

function storagePoolFromData(data: StoragePool, location: string): StoragePoolWithType {
    return { ...data, $type: "storage_pool", location };
}

export function getSingleQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/storage/admin/pools", {
                    signal,
                    params: { path: { location } },
                }),
            );
            return mapFromEntries(response, (pool: StoragePool) => [pool.uuid, storagePoolFromData(pool, location)]);
        },
    });
}

export const adminStoragePoolQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getSingleQuery, { location }),
);

export const adminAllStoragePoolsQueryAtom = atomAllLocationsQuery(adminStoragePoolQueryAtom, (results) =>
    mergeMaps(results),
);
